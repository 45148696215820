import { Components, Theme } from '@mui/material';

import IntegralCFBold from 'assets/fonts/IntegralCF-Bold.otf';
import VTMSpacelandBold from 'assets/fonts/VTMSpacelandUntwisted-Bold.woff2';
import VTMSpacelandHeavy from 'assets/fonts/VTMSpacelandUntwisted-Heavy.woff2';
import VTMSpacelandRegular from 'assets/fonts/VTMSpacelandUntwisted-Regular.woff2';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'IntegralCF';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('IntegralCF'), 
        url(${IntegralCFBold}) format('woff2');
    }
    @font-face {
      font-family: 'VTMSpaceland';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('VTMSpaceland'), 
        url(${VTMSpacelandRegular}) format('woff2');
    }
    @font-face {
      font-family: 'VTMSpaceland';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('VTMSpaceland'), 
        url(${VTMSpacelandBold}) format('woff2');
    }
    @font-face {
      font-family: 'VTMSpaceland';
      font-style: normal;
      font-weight: 900;
      font-display: swap;
      src: local('VTMSpaceland'), 
        url(${VTMSpacelandHeavy}) format('woff2');
    }
  `,
};
