import { styled } from '@mui/material/styles';

export const ParticipantImageContainer = styled('div')({
  position: 'relative',
  height: 'auto',
  overflow: 'hidden',
  paddingBottom: `${(3 / 2) * 100}%`,
});
export const ParticipantImageWrap = styled('div')({
  position: 'absolute',
  top: 0,
  margin: 0,
  padding: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const ParticipantImage = styled('img')({
  width: '100%',
  display: 'block',
  objectFit: 'cover',
});

export const GradientOverlay = styled('div')({
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 34.09%, rgba(0, 0, 0, 0.7) 100%)',
});

export const SelectedOverlay = styled('div')({
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(62, 205, 217, 0.4)',
});

export const VotedOverlay = styled('div')({
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background:
    'linear-gradient(270deg, rgba(219, 200, 138, 0.4) 0%, rgba(160, 118, 49, 0.4) 25%, rgba(241, 234, 214, 0.4) 50%, rgba(197, 164, 86, 0.4) 75%, rgba(136, 96, 37, 0.4) 90.5%, rgba(157, 114, 45, 0.4) 100%)',
});

export const ParticipantInfo = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px',
  zIndex: 2,
});
