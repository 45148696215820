import { Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { isDev, isStaging } from 'utils/env.utils';
import { inIframe } from 'utils/window.utils';

import { useAuth, usePostVotes, useVotingState } from 'queries';
import useParticipantSelectionsStore from 'store/participant-selection';

import { useIframeOffset } from './useIframeOffset';
import { LoadingButtonStyled } from './Vote.style';

const Vote = () => {
  const [error, setError] = useState<boolean | string>(false);
  const [success, setSuccess] = useState(false);
  const offsetInfo = useIframeOffset();
  const { isLoggedIn, login, isLoading: isLoginLoading } = useAuth();
  const { postVotes, isLoading } = usePostVotes();

  const {
    isStale,
    selections: selectedParticipants,
    resetSelections,
  } = useParticipantSelectionsStore((state) => ({
    selections: state.selections,
    resetSelections: state.resetSelections,
    isStale: state.isStale,
  }));

  const selectedParticipantsCount = Object.values(selectedParticipants).filter(
    (value) => value === true,
  ).length;

  const { data } = useVotingState();

  /**
   * When the selection becomes stale - reset it.
   * This should avoid voting for people you previously selected but never completed the voting for.
   */
  const stale = isStale();

  useEffect(() => {
    if (stale) {
      resetSelections();
    }
  }, [stale, resetSelections]);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        setSuccess(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [resetSelections, success]);

  const participantIds = Object.entries(selectedParticipants)
    .filter(([, selected]) => selected)
    .map(([key]) => key);

  const handleVoteSubmit = () => {
    if (!success && !error) {
      if (!isLoggedIn && !isDev()) {
        login();
      } else if (!!participantIds.length) {
        postVotes(
          { participantIds },
          {
            onSuccess: () => {
              resetSelections();
              const parentIframe = window.parentIFrame;
              if (parentIframe) {
                parentIframe.scrollToOffset(0, 0);
              } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
              setSuccess(true);
            },
            onError: (e) => {
              const error = e as AxiosError;
              if (error.response?.status === 400) {
                setError('vote.error.closed.message');
              } else if (error.response?.status === 401) {
                login();
                setError('vote.error.login.message');
              } else {
                setError('vote.error.unknown.message');
              }
            },
          },
        );
      }
    }
  };

  const getButtonLabel = () => {
    if (!!error) {
      return error as string;
    }
    if (!isLoggedIn && !isDev()) {
      return 'vote.button.login.label';
    }

    if (success) {
      return 'vote.success.message';
    }

    return 'vote.button.label';
  };

  if (
    !data?.votesAreOpen ||
    !participantIds.length ||
    selectedParticipantsCount <= 0
  ) {
    return null;
  }

  // we want to be able to vote without the iframe in dev and staging
  if (!offsetInfo && !inIframe() && (isDev() || isStaging())) {
    return (
      <Stack paddingTop={2}>
        <LoadingButtonStyled
          loading={isLoading || isLoginLoading}
          onClick={handleVoteSubmit}
          error={!!error}
          success={success}
        >
          <Typography fontWeight={700} color="rgba(33, 19, 77, 1)">
            <FormattedMessage
              id={getButtonLabel()}
              values={{ selectedParticipantsCount }}
            />
          </Typography>
        </LoadingButtonStyled>
      </Stack>
    );
  }

  if (!offsetInfo) return null;

  const voteContainerHeight = 170;

  return (
    <Stack
      sx={{
        display: 'flex',
        position: 'absolute',
        height: voteContainerHeight,
        top: Math.max(
          Math.min(
            offsetInfo.offset - voteContainerHeight,
            offsetInfo.iframeHeight - (voteContainerHeight - 12),
          ),
          500,
        ),
        left: 0,
        width: '100%',
        p: 2,
        zIndex: 100,
        opacity: offsetInfo.isScrolling ? 0 : 1,
        transform: offsetInfo.isScrolling
          ? 'translateY(100%)'
          : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms',
        backgroundColor: 'white',
        boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px 10px 0px 0px',
      }}
    >
      <Typography mb={1} textAlign="center">
        <FormattedMessage
          id="vote.button.description"
          values={{ selectedParticipantsCount: selectedParticipantsCount }}
        />
      </Typography>
      <LoadingButtonStyled
        loading={isLoading || isLoginLoading}
        variant="contained"
        onClick={handleVoteSubmit}
        error={!!error}
        success={success}
        sx={{
          width: { xs: '100%', md: 'auto' },
          fontSize: error ? { xs: '0.8rem', md: '1rem' } : undefined,
          paddingX: error ? { xs: 0, md: 4 } : undefined,
        }}
      >
        <Typography fontWeight={700} color="rgba(33, 19, 77, 1)">
          <FormattedMessage
            id={getButtonLabel()}
            values={{ selectedParticipantsCount: selectedParticipantsCount }}
          />
        </Typography>
      </LoadingButtonStyled>
    </Stack>
  );
};

export default Vote;
