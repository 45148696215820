import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { useVotingStatus } from 'hooks/useVotingStatus';
import {
  useAuth,
  useInitialParticipants,
  useParticipants,
  useVotes,
  useVotingState,
} from 'queries';
import useParticipantSelectionsStore from 'store/participant-selection';
import { VotingStatus } from 'types/voting.types';

import { ParticipantsList } from '../ParticipantsList';

const Participants = () => {
  const { data } = useVotingState();
  const currentStatus = useVotingStatus();
  const { isLoggedIn } = useAuth();
  const { votes } = useVotes({
    enabled: data?.votesAreOpen && isLoggedIn,
  });

  const { participants, refetch } = useParticipants();
  const { initialParticipants, refetch: refetchInitialParticipants } =
    useInitialParticipants();
  const selections = useParticipantSelectionsStore((state) => state.selections);
  const queryClient = useQueryClient();

  // useRef to keep track of the previous value of votesAreOpen
  const prevVotesAreOpen = useRef(data?.votesAreOpen);

  useEffect(() => {
    // Only refetch if votesAreOpen changed from false to true
    if (prevVotesAreOpen.current === false && data?.votesAreOpen === true) {
      refetch();
      refetchInitialParticipants();
    }
  }, [data?.votesAreOpen, queryClient, refetch, refetchInitialParticipants]);

  const votesEnabled = data?.votesAreOpen;

  // map the votes to an object that has the same type as the selected participants objects
  const mappedVotes = votes.reduce((acc: Record<string, boolean>, vote) => {
    acc[vote] = true;
    return acc;
  }, {});

  return (
    <>
      <ParticipantsList
        participants={
          currentStatus === VotingStatus.LAST_EPISODE_FINISHED
            ? initialParticipants
            : participants
        }
        selected={selections}
        votesEnabled={votesEnabled}
        votes={mappedVotes}
      />
    </>
  );
};

export default Participants;
