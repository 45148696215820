import { Box } from '@mui/material';
import React from 'react';

import useParticipantSelectionsStore from 'store/participant-selection';
import { Participant } from 'types/participants.types';

import { ParticipantCard } from '../ParticipantCard';
import { CardState } from '../ParticipantCard/ParticipantCard';

interface Props {
  participants: Array<Participant>;
  selected: Record<string, boolean>;
  votesEnabled?: boolean;
  votes: Record<string, boolean>;
}

const ParticipantsList = ({
  participants,
  selected,
  votesEnabled,
  votes,
}: Props) => {
  const toggleSelection = useParticipantSelectionsStore(
    (state) => state.toggleSelection,
  );

  const getGridTemplateColumns = () => {
    const count = participants.length;

    return {
      xs: count === 1 ? 'auto' : 'repeat(2, auto)',
      sm: count < 3 ? `repeat(${count}, auto)` : 'repeat(3, auto)',
    };
  };

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns={getGridTemplateColumns()}
      sx={(theme) => ({
        [theme.breakpoints.down(380)]: {
          gridTemplateColumns: 'auto',
        },
      })}
      gap={{ xs: 2, md: 4 }}
    >
      {participants.map((participant) => {
        let cardState: CardState = 'default';
        if (votes[participant.participantId]) {
          cardState = 'vote';
        } else if (selected[participant.participantId]) {
          cardState = 'selected';
        }
        return (
          <ParticipantCard
            key={participant.participantId}
            participant={participant}
            state={cardState}
            votesEnabled={votesEnabled}
            onSelect={toggleSelection}
          />
        );
      })}
    </Box>
  );
};

export default React.memo(ParticipantsList);
