import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ParticipantSelectionState {
  selections: { [participantId: string]: boolean };
  lastUpdated: number;
  toggleSelection: (participantId: string) => void;
  setSelection: (participantIds: Record<string, boolean>) => void;
  resetSelections: () => void;
  isStale: () => boolean;
}

const useParticipantSelectionsStore = create(
  persist(
    (set, get) => ({
      selections: {},
      lastUpdated: Date.now(),
      toggleSelection: (participantId: string) =>
        set((state: ParticipantSelectionState) => ({
          selections: {
            ...state.selections,
            [participantId]: !state.selections[participantId],
          },
          lastUpdated: Date.now(),
        })),
      setSelection: (participantIds: Record<string, boolean>) =>
        set(() => ({
          selections: participantIds,
          lastUpdated: Date.now(),
        })),

      resetSelections: () =>
        set(() => ({
          selections: {},
          lastUpdated: Date.now(),
        })),

      isStale: () => {
        /**
         * The user's vote selection turns stale after a set time.
         */
        const state = get();
        // Minutes * Seconds * Miliseconds
        const staleTimeInMs = 15 * 60 * 1000;
        return Date.now() - state.lastUpdated > staleTimeInMs;
      },
    }),
    {
      name: 'participant-selections',
      partialize: (state: ParticipantSelectionState) => ({
        selections: state.selections,
        lastUpdated: state.lastUpdated,
      }),
    },
  ),
);

export default useParticipantSelectionsStore;
