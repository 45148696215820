const YEAR = 2025;
const HOUR_START = 19;
const HOUR_END = 21;
const MINUTES_START = 55;
const MINUTES_END = 0;

/**
 * Overview of all the episodes.
 *
 * DISCLAIMER: Months start at 0.
 * January: 0, February: 1, ...
 */
export const EpisodeDates = [
  // March 2025 (Month 2)
  {
    start: new Date(YEAR, 2, 9, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 2, 9, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 2, 16, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 2, 16, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 2, 30, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 2, 30, HOUR_END, MINUTES_END, 0),
  },
  // April 2025 (Month 3)
  {
    start: new Date(YEAR, 3, 6, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 3, 6, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 3, 13, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 3, 13, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 3, 20, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 3, 20, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 3, 27, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 3, 27, HOUR_END, MINUTES_END, 0),
  },
  // May 2025 (Month 4)
  {
    start: new Date(YEAR, 4, 4, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 4, 4, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 4, 11, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 4, 11, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 4, 18, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 4, 18, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, 4, 25, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 4, 25, HOUR_END, MINUTES_END, 0),
  },
  // June 2025 (Month 5 in JS)
  {
    start: new Date(YEAR, 5, 1, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, 5, 1, HOUR_END, MINUTES_END, 0),
  },
];
