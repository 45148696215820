import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useAuth, useParticipants, useVotes, useVotingState } from 'queries';

const UserVoted = () => {
  const { isLoggedIn } = useAuth();
  const { data } = useVotingState();
  const { votes } = useVotes({
    enabled: data?.votesAreOpen && isLoggedIn,
  });
  const { participants } = useParticipants();

  const getMessageId = (baseId: string) =>
    `${baseId}${isLoggedIn ? '.logged_in' : ''}`;

  const votedAll = votes.length === participants.length;

  return (
    <Stack
      py={{ xs: 2, md: 2.5 }}
      px={{ xs: 3, md: 2 }}
      textAlign={{ xs: 'left', md: 'center' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      flex={1}
      gap={2}
    >
      <Typography
        variant="h1"
        color="white"
        mx={2}
        sx={{ whiteSpace: 'pre-line' }}
      >
        <FormattedMessage
          id={getMessageId('episode.status.user_voted.title')}
          values={{ votes: votes.length }}
        />
      </Typography>

      <Typography
        variant="body1"
        color="white"
        mx={2}
        sx={{ whiteSpace: 'pre-line' }}
      >
        <FormattedMessage
          id={getMessageId(
            `episode.status.user_voted.description${votedAll ? '.full' : ''}`,
          )}
          values={{ votes: votes.length }}
        />
      </Typography>
    </Stack>
  );
};

export default UserVoted;
