import { addDays, isAfter } from 'date-fns';

import { EpisodeDates } from 'constants/episode.constants';

/**
 * The first 2 episodes are not LIVE, they are pre-recorded.
 * Only show 'LIVE' after the first 2 episodes.
 *
 * 1 day has been added to the episode timing.
 * This makes sure they have marked the episode as 'not live' in time.
 */
export const isDateAfterSecondEpisode = () =>
  isAfter(new Date(), addDays(EpisodeDates[1].end, 1));
