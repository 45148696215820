import { Box } from '@mui/material';

import background from 'assets/jpg/bg_episode_status.jpg';

import EpisodeStatusContent from './EpisodeStatusContent';

const EpisodeStatus = () => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(90deg, #DBC88A, #A07631, #F1EAD6, #C5A456, #886025, #9D722D)',
        padding: '2px',
        borderRadius: '12px',
        mb: { xs: 2, md: 4 },
      }}
    >
      <Box
        sx={{
          background: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          // Slightly smaller to account for fake border.
          borderRadius: '11px',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <EpisodeStatusContent />
      </Box>
    </Box>
  );
};

export default EpisodeStatus;
