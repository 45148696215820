import { Check } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React from 'react';

import config from 'config/env';

import { Participant } from 'types/participants.types';

import ParticipantInfo from './components/ParticipantInfo';
import {
  GradientOverlay,
  ParticipantImage,
  ParticipantImageContainer,
  ParticipantImageWrap,
  SelectedOverlay,
  VotedOverlay,
} from './Participant.style';

export type CardState = 'vote' | 'selected' | 'default';
interface Props {
  participant: Participant;
  votesEnabled?: boolean;
  state: CardState;
  onSelect: (participantId: string) => void;
}

const ParticipantCard = ({
  participant,
  votesEnabled,
  state,
  onSelect,
}: Props) => {
  const voted = state === 'vote';
  const selected = state === 'selected';

  let border = '2px solid transparent';

  if (voted) {
    border = '2px solid #C5A456';
  } else if (selected) {
    border = '2px solid rgba(62, 205, 217, 1)';
  }

  return (
    <Stack
      component="button"
      textAlign="left"
      justifyContent="flex-end"
      p={2}
      minWidth={{ xs: 164, md: 261 }}
      minHeight={{ xs: 220, md: 348 }}
      position="relative"
      overflow="hidden"
      border={border}
      borderRadius={{ xs: 3, md: 4 }}
      sx={{
        cursor: votesEnabled && !voted ? 'pointer' : 'default',
        aspectRatio: '0.7442',
      }}
      onClick={() => {
        if (votesEnabled && !voted) onSelect(participant.participantId);
      }}
    >
      {votesEnabled || voted ? (
        <Stack
          width={32}
          height={32}
          borderRadius={1}
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={8}
          left={8}
          zIndex={2}
          color="secondary.main"
          sx={
            voted
              ? {
                  background:
                    'linear-gradient(316.28deg, #DBC88A -126.84%, #A07631 -65.68%, #F1EAD6 -7.78%, #C5A456 56.65%, #886025 94.57%, #9D722D 117.81%)',
                }
              : {
                  backgroundColor: selected ? 'primary.main' : 'transparent',
                  borderWidth: selected ? 0 : 1,
                  borderColor: 'white',
                  borderStyle: voted ? 'none' : 'solid',
                }
          }
        >
          {selected || voted ? <Check /> : null}
        </Stack>
      ) : null}

      <ParticipantInfo participant={participant} voted={voted} />

      {!selected && !voted && <GradientOverlay />}
      {selected && <SelectedOverlay />}
      {voted && <VotedOverlay />}

      <Box
        position="absolute"
        overflow="hidden"
        top={0}
        right={0}
        bottom={0}
        left={0}
        sx={{ mixBlendMode: voted ? 'luminosity' : undefined }}
      >
        <ParticipantImageContainer>
          <ParticipantImageWrap>
            <ParticipantImage
              src={`${config.API_CLIENT_URL}${participant.imagePath}`}
            />
          </ParticipantImageWrap>
        </ParticipantImageContainer>
      </Box>
    </Stack>
  );
};

export default React.memo(ParticipantCard);
