import { Box } from '@mui/material';
import { useMemo } from 'react';

import Star from 'assets/png/star.png';

import { useVotingStatus } from 'hooks/useVotingStatus';
import { useVotingState } from 'queries';
import { VotingStatus } from 'types/voting.types';

import { CountdownStatus } from '../CountdownStatus';
import { EpisodeLiveVotesClosed } from '../EpisodeLiveVotesClosed';
import { EpisodeLiveVotesOpen } from '../EpisodeLiveVotesOpen';
import { EpisodeNotLive } from '../EpisodeNotLive';
import { LastEpisodeFinished } from '../LastEpisodeFinished';
import { LiveBadge } from '../LiveBadge';
import { UserVoted } from '../UserVoted';

const VotingStatusComponents = {
  [VotingStatus.COUNTDOWN]: CountdownStatus,
  [VotingStatus.LIVE_VOTES_CLOSED]: EpisodeLiveVotesClosed,
  [VotingStatus.LIVE_VOTES_OPEN]: EpisodeLiveVotesOpen,
  [VotingStatus.HAS_VOTED]: UserVoted,
  [VotingStatus.NOT_LIVE]: EpisodeNotLive,
  [VotingStatus.LAST_EPISODE_FINISHED]: LastEpisodeFinished,
};

const EpisodeStatusContent = () => {
  const currentStatus = useVotingStatus();
  const { data } = useVotingState();

  const Component = useMemo(
    () => VotingStatusComponents[currentStatus],
    [currentStatus],
  );

  return (
    <Box position="relative" flex={1}>
      {data?.isLive && <LiveBadge />}
      <Component />
      <Box
        component="img"
        src={Star}
        sx={{
          position: 'absolute',
          bottom: -15,
          right: 8,
          width: 26,
          height: 'auto',
        }}
        alt="Star"
      />
    </Box>
  );
};

export default EpisodeStatusContent;
