import { isAfter } from 'date-fns';

import { EpisodeDates } from 'constants/episode.constants';
import { useAuth, useCurrentEpisode, useVotes, useVotingState } from 'queries';
import { VotingStatus } from 'types/voting.types';

export const useVotingStatus = () => {
  const { isLoggedIn } = useAuth();
  const { data } = useVotingState();
  const { currentEpisode } = useCurrentEpisode();
  const { votes } = useVotes({
    enabled: data?.votesAreOpen && isLoggedIn,
  });

  switch (true) {
    case data?.votesAreOpen:
      return !!votes.length
        ? VotingStatus.HAS_VOTED
        : VotingStatus.LIVE_VOTES_OPEN;
    case data?.isLive && !data?.votesAreOpen:
      return VotingStatus.LIVE_VOTES_CLOSED;
    case !data?.isLive && currentEpisode?.shouldPoll:
      return VotingStatus.NOT_LIVE;
    case !currentEpisode?.date &&
      isAfter(new Date(), EpisodeDates[EpisodeDates.length - 1].end):
      return VotingStatus.LAST_EPISODE_FINISHED;
    default:
      return VotingStatus.COUNTDOWN;
  }
};
