import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import LogoGlobe from '../../../assets/png/logo_globe.png';

const EpisodeLiveVotesClosed = () => {
  return (
    <Stack
      py={{ xs: 2, md: 2.5 }}
      px={{ xs: 3, md: 2 }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      position="relative"
      overflow="hidden"
    >
      <Box
        component="img"
        src={LogoGlobe}
        sx={{
          position: 'absolute',
          bottom: -225,
          right: -145,
          width: 373,
          height: 'auto',
          transform: 'rotate(-11.2deg)',
        }}
        alt="Globe"
      />
      <Box
        sx={{
          textAlign: { xs: 'flex-start', md: 'center' },
          maxWidth: { xs: 200, md: 350 },
          zIndex: 1,
        }}
      >
        <Typography mb={{ xs: 1, md: 2 }} variant="h2" color="white">
          <FormattedMessage id={'episode.status.live.votes_disabled.title'} />
        </Typography>
        <Typography color="white">
          <FormattedMessage id="episode.status.live.votes_disabled.description" />
        </Typography>
      </Box>
    </Stack>
  );
};

export default EpisodeLiveVotesClosed;
