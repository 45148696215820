import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import useParticipantOrderStore from 'store/participant-order';
import { Participant } from 'types/participants.types';

import { filterEliminatedParticipants, shuffleParticipants } from './utils';

const getParticipants = async () => {
  const { data } = await apiService.getParticipants();

  return data;
};

export const useParticipants = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: QueryKeys.participants.all(),
    queryFn: getParticipants,
    select: (data) => filterEliminatedParticipants(data),
  });

  const { participantIds, setParticipantIds } = useParticipantOrderStore();

  useEffect(() => {
    if (data) {
      const shuffledParticipantIds = shuffleParticipants(
        data.map((p) => p.participantId),
      );

      setParticipantIds(shuffledParticipantIds);
    }
  }, [data, setParticipantIds]);

  // Sort participants based on the stored order
  const sortedParticipants = data
    ? (participantIds
        .map((id) => data.find((p) => p.participantId === id))
        .filter(Boolean) as Array<Participant>)
    : [];

  return {
    participants: sortedParticipants,
    isLoading,
    refetch,
  };
};
