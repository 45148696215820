import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'queries';

const Login = () => {
  const { isLoggedIn, isLoading, login } = useAuth();

  if (isLoggedIn || isLoading) return null;

  return (
    <Stack
      bgcolor="grey.300"
      p={2}
      my={3}
      alignItems="center"
      textAlign="center"
      borderRadius="12px"
      spacing={2}
    >
      <Box display="flex">
        <Button
          variant="contained"
          onClick={login}
          sx={{ flexGrow: { xs: 1, md: 0 } }}
        >
          <Typography fontWeight={700} color="rgba(33, 19, 77, 1)">
            <FormattedMessage id="login.button.label" />
          </Typography>
        </Button>
      </Box>
    </Stack>
  );
};

export default Login;
