import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

const EpisodeNotLive = () => {
  return (
    <Box
      py={3}
      px={{ xs: 4, md: 0 }}
      textAlign={{ xs: 'left', md: 'center' }}
      justifyContent="center"
    >
      <Typography variant="h2" color="white">
        <FormattedMessage id="episode.status.not_live.title" />
      </Typography>
    </Box>
  );
};

export default EpisodeNotLive;
