import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Participant } from 'types/participants.types';

interface Props {
  participant: Participant;
  voted?: boolean;
}

const ParticipantInfo = ({ participant, voted }: Props) => {
  return (
    <Box
      position="relative"
      display="flex"
      zIndex={2}
      sx={{ opacity: voted ? 0.6 : 1 }}
    >
      <Stack gap={1}>
        <Typography
          variant="h4"
          lineHeight={1}
          color="white"
          fontSize={{ xs: '1.125rem', md: '1.75rem' }}
        >
          {participant.firstName}
        </Typography>
        <Typography
          sx={{ opacity: 0.75 }}
          variant="caption"
          color="white"
          fontSize={{ xs: '0.75rem', md: '1rem' }}
        >
          <FormattedMessage
            id={`participant.partner.${participant.participantId.toLowerCase()}`}
          />
        </Typography>
      </Stack>
    </Box>
  );
};

export default React.memo(ParticipantInfo);
