import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useCurrentEpisode } from 'queries';

import { CountdownCounter } from '../CountdownCounter';

const CountdownStatus = () => {
  const { currentEpisode } = useCurrentEpisode();

  return (
    <Box
      py={5}
      px={{ xs: 4, md: 0 }}
      textAlign="center"
      justifyContent="center"
      justifySelf="center"
      width={{ md: 320 }}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Typography variant="h2" color="white">
        {!!currentEpisode?.date && (
          <FormattedMessage
            id="episode.status.countdown.title"
            values={{
              date: capitalize(
                format(currentEpisode.date.start, 'd MMMM', { locale: nlBE }),
              ),
            }}
          />
        )}
      </Typography>
      <CountdownCounter />
      {!!currentEpisode?.date && (
        <Typography color="white">
          <FormattedMessage
            id="episode.status.countdown.description"
            values={{
              date: capitalize(
                format(currentEpisode.date.start, 'eeee dd/MM', {
                  locale: nlBE,
                }),
              ),
            }}
          />
        </Typography>
      )}
    </Box>
  );
};

export default CountdownStatus;
