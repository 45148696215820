import { isProd } from 'utils/env.utils';

interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  LOGIN_URL: string;
  IFRAME_CLIENT_URL: string;
  CLIENT_URL: string;
  API_BASE: string;
  API_CLIENT_URL: string;
  API_SERVER_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  PHRASE: {
    PROJECT_ID: string;
    ACCOUNT_ID: string;
  };
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn: 'https://ef27c302e85a05038c58f02c435837ee@o190235.ingest.us.sentry.io/4508890884669440',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },
  PHRASE: {
    PROJECT_ID: process.env.REACT_APP_PHRASE_PROJECT_ID || '',
    ACCOUNT_ID: process.env.REACT_APP_PHRASE_ACCOUNT_ID || '',
  },

  // dev
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'nl-BE',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',

  // auth
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL || '',
  IFRAME_CLIENT_URL: process.env.REACT_APP_IFRAME_CLIENT_URL || '',
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL || '',
};

export default config;
