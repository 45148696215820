import { GetParticipantsResponse } from 'types/participants.types';

/// Fisher-Yates Shuffle Algorithm to randomize an array
export const shuffleParticipants = (participantIds: Array<string>) => {
  let currentIndex = participantIds.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = participantIds[currentIndex];
    participantIds[currentIndex] = participantIds[randomIndex];
    participantIds[randomIndex] = temporaryValue;
  }

  return participantIds;
};

export const filterEliminatedParticipants = (data: GetParticipantsResponse) => {
  let filteredParticipants = data.participants.filter(
    (p) => p.isStillInContest,
  );

  filteredParticipants = filteredParticipants.filter((p) => p.canBeVotedFor);

  return filteredParticipants;
};
