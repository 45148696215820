import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import Globe from 'assets/png/logo_globe.png';

const EpisodeLiveVotesOpen = () => {
  return (
    <Stack
      py={{ xs: 2, md: 2.5 }}
      px={2}
      alignItems="center"
      position="relative"
      overflow="hidden"
    >
      <Box
        component="img"
        src={Globe}
        sx={{
          position: 'absolute',
          bottom: -30,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 180,
          height: 'auto',
        }}
        alt="GlobeLogo"
      />

      <Stack
        sx={{
          textAlign: { xs: 'left', md: 'center' },
          minHeight: { xs: 160, md: 130 },
          zIndex: 1,
        }}
      >
        <Typography variant="h1" color="white">
          <FormattedMessage id="episode.status.live.votes_enabled.title" />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EpisodeLiveVotesOpen;
