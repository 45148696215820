import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import LogoGlobe from 'assets/png/logo_globe.png';

import { useParticipants } from 'queries';

const LastEpisodeFinished = () => {
  const { participants } = useParticipants();
  const winner = participants[0];

  return (
    <Box
      py={2}
      px={{ xs: 4, md: 4 }}
      textAlign="center"
      justifyContent="center"
      position="relative"
      overflow="hidden"
      gap={2}
    >
      <Box
        component="img"
        src={LogoGlobe}
        sx={{
          position: 'absolute',
          top: -140,
          right: -190,
          width: 310,
          height: 'auto',
          transform: 'rotate(45.2deg)',
        }}
        alt="Globe"
      />
      <Typography
        color="white"
        mb={2}
        marginInline={4}
        style={{ whiteSpace: 'pre-line' }}
      >
        <FormattedMessage id="episode.status.finished.title" />
      </Typography>
      <Stack
        sx={{
          background:
            'linear-gradient(45deg, #DBC88A, #A07631, #F1EAD6, #C5A456, #886025, #9D722D)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          color="white"
          maxWidth={{ xs: 240, md: '100%' }}
        >
          {winner?.participantId ? (
            <FormattedMessage
              id={`participant.fullname.${winner?.participantId?.toLowerCase()}`}
            />
          ) : (
            '-'
          )}
        </Typography>
      </Stack>
      <Typography color="white" mb={3}>
        {winner?.participantId ? (
          <FormattedMessage
            id={`participant.partner.${winner?.participantId?.toLowerCase()}`}
          />
        ) : (
          '-'
        )}
      </Typography>
      <Typography fontWeight={700} color="white">
        <FormattedMessage id="episode.status.finished.caption" />
      </Typography>
    </Box>
  );
};

export default LastEpisodeFinished;
