import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import { isDateAfterSecondEpisode } from 'utils/episode.utils';

import { PulsateLive } from './LiveBadge.styles';

const LiveBadge = () => {
  /**
   * The first 2 episodes are not LIVE, they are pre-recorded.
   * Only show 'LIVE' after the first 2 episodes.
   *
   * 1 day has been added to the episode timing.
   * This makes sure they have marked the episode as 'not live' in time.
   */
  const isAfterSecondEpisode = isDateAfterSecondEpisode();

  return (
    <Box
      position="absolute"
      sx={{
        // Small screens: top-right positioning
        top: { xs: 8, md: -15 },
        right: { xs: 8, md: 'auto' },

        // Large screens: centered positioning
        left: { xs: 'auto', md: '50%' },
        transform: { xs: 'none', md: 'translateX(-50%)' },
      }}
      px={1}
      py={0.75}
      color="white"
      borderRadius={5}
      display="flex"
      bgcolor="#F90730"
      alignItems="center"
    >
      <PulsateLive
        bgcolor="white"
        boxShadow="0px 0px 4px 3px #FFFFFF8C"
        borderRadius="50%"
        width={10}
        height={10}
        mr={0.75}
      />
      <Typography
        fontWeight={900}
        lineHeight="14.4px"
        component="span"
        fontSize={12}
      >
        {isAfterSecondEpisode ? (
          <FormattedMessage id="label.live" />
        ) : (
          <FormattedMessage id="label.on_air" />
        )}
      </Typography>
    </Box>
  );
};

export default LiveBadge;
