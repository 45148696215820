import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';

type Props = {
  error?: boolean;
  success?: boolean;
} & LoadingButtonProps;

export const LoadingButtonStyled = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'success' && prop !== 'error',
})<Props>(({ theme, error }) => ({
  // add a background-color transition when success is true
  transition: 'background-color 0.5s ease-in, width 1s ease-in',
  // add a background-color when error is true

  ...(error && {
    backgroundColor: `${theme.palette.error.main} !important`,
  }),
}));
